<template>
  <div class="content-visibility-container">
    <div class="content-visibility-container__group mr-5">
      <p>{{ $t('opportunities.editOpportunity.content.visibility.whoTitle') }}</p>
      <b-form-checkbox
        class="visibility-checkbox"
        :checked="showToVerifiedInvestorsOnly"
        :disabled="!isEdit"
        @input="(value) => onChange('showToVerifiedInvestorsOnly', value)"
      >
        <span>{{ $t('opportunities.editOpportunity.content.visibility.showToVerifiedInvestorsOnly') }}</span>
      </b-form-checkbox>
    </div>
    <div class="content-visibility-container__group">
      <p>{{ $t('opportunities.editOpportunity.content.visibility.whereTitle') }}</p>
      <b-form-checkbox
        class="visibility-checkbox mb-3"
        :checked="showInOpportunity"
        :disabled="!isEdit"
        @input="(value) => onChange('showInOpportunity', value)"
      >
        <span>{{ $t('opportunities.editOpportunity.content.visibility.showInOpportunity') }}</span>
      </b-form-checkbox>
      <b-form-checkbox
        class="visibility-checkbox"
        :checked="showInTokenDetails"
        :disabled="!isEdit"
        @input="(value) => onChange('showInTokenDetails', value)"
      >
        <span>{{ $t('opportunities.editOpportunity.content.visibility.showInTokenDetails') }}</span>
      </b-form-checkbox>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContentVisibility',
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    showToVerifiedInvestorsOnly: {
      type: Boolean,
      default: false,
    },
    showInTokenDetails: {
      type: Boolean,
      default: true,
    },
    showInOpportunity: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    onChange(type, value) {
      this.$emit('valueChanged', { visibilityType: type, value });
    },
  },
};
</script>

<style lang="scss" scoped>
  .content-visibility-container {
    display: flex;

    &__group {
      p {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.93;
        color: #617d8d;
      }
      .visibility-checkbox {
        padding-left: 2em;
      }
    }
  }
</style>
