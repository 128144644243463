export default {
  data() {
    return {
      editMode: false,
    };
  },
  methods: {
    changeMode() {
      this.editMode = !this.editMode;
    },
    setEditMode(editMode) {
      this.editMode = !!editMode;
    },
  },
};
