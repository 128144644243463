<template>
  <div class="col-6 d-flex justify-content-end">
    <b-button
      v-if="!editMode"
      variant="primary"
      size="sm"
      :disabled="disableEdit"
      @click="$emit('changeMode')"
    >
      <i class="ion ion-md-create" />
      {{ $t('common.button.edit') }}
    </b-button>
    <b-button
      v-if="editMode"
      variant="primary"
      size="sm"
      class="mr-3 edit-mode-cancel-button"
      @click="$emit('changeMode')"
    >
      {{ $t('common.button.cancel') }}
    </b-button>
    <b-button
      v-if="editMode"
      variant="primary"
      size="sm"
      @click="$emit('update')"
    >
      <i class="ion ion-md-checkmark" />
      {{ $t('common.button.saveChanges') }}
    </b-button>
  </div>
</template>

<script>
export default {
  name: 'OppEditButtons',
  props: {
    editMode: {
      type: Boolean,
      required: true,
    },
    disableEdit: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
