<template>
  <div>
    <template v-if="editMode">
      <cp-input
        :validate="validate"
        :value="currentValue"
        :label="label"
        :error="error"
        :placeholder="placeholder"
        @input="onChangeInput"
      />
    </template>
    <template v-if="!editMode">
      {{ value }}
    </template>
    <div
      v-if="thumnailUrl"
      class="card border-light mt-3 d-flex justify-content-center align-items-center py-1 w-100 cp-file-preview"
    >
      <img
        v-b-modal.opportunity-image
        class="d-block w-100"
        :src="thumnailUrl"
      >
    </div>
    <b-button
      variant="outline-dark"
      size="md"
      @click="viewVideo"
    >
      <i
        v-if="youtubeId"
        class="ion ion-logo-youtube"
      />
      <i
        v-if="vimeoId"
        class="ion ion-logo-vimeo"
      />
      <i
        v-if="!youtubeId && !vimeoId"
        class="ion ion-ios-play"
      />
      {{ $t('opportunities.editOpportunity.content.videoBox.play.label') }}
    </b-button>

    <b-modal
      ref="previewVideo"
      size="lg"
      hide-header
      ok-only
      centered
      no-close-on-esc
      no-close-on-backdrop
      modal-class="cp-preview-modal"
      @ok="hideVideo"
    >
      <iframe
        id="preview-kyc-iframe"
        :src="iframeUrl"
        frameborder="0"
        width="500"
        height="315"
        allowfullscreen=""
      />
    </b-modal>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import { CpInput } from '~/components/common/standalone-components/inputs';

export default {
  name: 'VideoInput',
  components: {
    CpInput,
  },
  props: {
    error: {
      type: String,
      default: '',
    },
    validate: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number],
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      thumnailUrl: '',
      controlledValue: '',
      controlledError: '',
      youtubeId: '',
      vimeoId: '',
      iframeUrl: '',
    };
  },
  computed: {
    currentValue() {
      return this.value !== null ? this.value : this.controlledValue;
    },
  },
  watch: {
    value: {
      handler(val, oldVal) {
        if (val !== oldVal && val) {
          this.onChangeInput(val);
        }
      },
      immediate: true,
    },
  },
  created() {
    this.getImage(this.currentValue);
  },
  methods: {
    ...mapActions('opportunities', ['getVimeoVideoThumbnail']),
    onChangeInput(val) {
      this.$emit('input', val);
      this.getImage(val);
    },
    getImage(url) {
      let imageUrl = '';
      this.youtubeId = '';
      this.vimeoId = '';
      this.iframeUrl = '';
      const ytId = this.parseYoutubeIdFromUrl(url);
      if (ytId) {
        imageUrl = this.getYoutubeThumbUrl(ytId);
        this.youtubeId = ytId;
      }
      if (!ytId) {
        const vmId = this.parseVimeoIdFromUrl(url);
        this.isYoutube = true;
        if (vmId) {
          imageUrl = this.getVimeoThumbUrl(vmId);
          this.vimeoId = vmId;
        }
      }
      this.thumnailUrl = imageUrl;
      this.setIframeUrl(url);
    },
    parseVimeoIdFromUrl(vimeoUrl) {
      const match = /vimeo.*\/(\d+)/i.exec(vimeoUrl);
      if (match) return match[1];
      return null;
    },
    getVimeoThumbUrl(videoId) {
      this.getVimeoVideoThumbnail({ videoId })
        .then(({ data }) => {
          if (data && data.length) {
            this.thumnailUrl = data[0].thumbnail_large;
          }
        });
    },
    parseYoutubeIdFromUrl(ytUrl) {
      const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      const match = ytUrl.match(regExp);
      return (match && match[7].length === 11) ? match[7] : false;
    },
    getYoutubeThumbUrl(ytId) {
      return ` https://img.youtube.com/vi/${ytId}/sddefault.jpg`;
    },
    setIframeUrl(url) {
      if (this.youtubeId) { this.iframeUrl = `http://www.youtube.com/embed/${this.youtubeId}`; }
      if (this.vimeoId) {
        this.iframeUrl = `https://player.vimeo.com/video/${this.vimeoId}`;
      }
      if (!this.youtubeId && !this.vimeoId) {
        if (typeof url === 'string') {
          url = url.replace(/script/gim, '');
        }
        this.iframeUrl = url;
      }
    },
    viewVideo() {
      this.$refs.previewVideo.show();
    },
    hideVideo() {

    },
  },
};
</script>
