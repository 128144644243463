<template>
  <div class="card mb-5 w-100">
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col-md-6">
          <h4
            class="mb-0 text-primary"
          >
            <i
              class="ion ion-ios-chatboxes"
            />{{ $t('opportunities.editOpportunity.content.telegramBox.title') }}
            <b-badge v-if="content.tempId">
              {{ $t('opportunities.editOpportunity.content.newText') }}
            </b-badge>
          </h4>
        </div>
        <div class="d-flex p-2 w-50 flex-row justify-content-end">
          <opp-edit-buttons
            :edit-mode="editMode"
            @changeMode="changeModeProxy"
            @update="save"
          />
          <cp-button
            class=""
            variant="primary"
            icon="ion ion-ios-trash"
            @click="deleteAction"
          >
            {{ $t('opportunities.editOpportunity.content.deleteConfirmation.deleteButton') }}
          </cp-button>
        </div>
      </div>
    </div>
    <div
      v-if="editMode"
      class="card-body"
    >
      <cp-input-container
        ref="cpInputContainer"
        v-model="model"
      >
        <div class="row">
          <div class="col-md-8">
            <cp-input
              key="title"
              v-model="model.title"
              class="mb-3"
              :label="$t('opportunities.editOpportunity.content.telegramBox.inputs.title.label')"
              :placeholder="$t('opportunities.editOpportunity.content.telegramBox.inputs.title.placeholder')"
              name="title"
              validate="required"
            />
            <cp-select
              key="resourceType"
              v-model="model.resourceType"
              name="resourceType"
              :label="$t('opportunities.editOpportunity.content.telegramBox.inputs.widgetType.label')"
              :options="widgetTypes"
            />
            <cp-input
              key="resourceId"
              v-model="model.resourceId"
              class="mb-3"
              :label="$t('opportunities.editOpportunity.content.telegramBox.inputs.widgetResourceId.label')"
              :placeholder="$t('opportunities.editOpportunity.content.telegramBox.inputs.widgetResourceId.placeholder')"
              name="resourceId"
              validate="required"
            />
            <cp-input
              key="order"
              v-model="model.order"
              class="mb-5"
              input-type="number"
              :label="$t('opportunities.editOpportunity.content.telegramBox.inputs.order.label')"
              :placeholder="$t('opportunities.editOpportunity.content.telegramBox.inputs.order.placeholder')"
              name="order"
              validate="required"
            />
            <content-visibility
              :is-edit="editMode"
              :show-to-verified-investors-only="model.showToVerifiedInvestorsOnly"
              :show-in-opportunity="model.showInOpportunity"
              :show-in-token-details="model.showInTokenDetails"
              @valueChanged="onContentVisibilityChanged"
            />
          </div>
        </div>
      </cp-input-container>
    </div>
    <div
      v-else
      class="card-body"
    >
      <div class="row">
        <div class="col-md-12">
          <h5>{{ content.title }}</h5>
        </div>
        <div class="col-md-12">
          <p><b>{{ $t('opportunities.editOpportunity.content.telegramBox.inputs.widgetType.label') }}:</b> {{ widgetContent.resourceType }} </p>
          <p><b>{{ $t('opportunities.editOpportunity.content.telegramBox.inputs.widgetResourceId.label') }}:</b> {{ widgetContent.resourceId }} </p>
          <p> {{ content.order }} </p>
          <content-visibility
            :is-edit="editMode"
            :show-to-verified-investors-only="content.showToVerifiedInvestorsOnly"
            :show-in-opportunity="content.showInOpportunity"
            :show-in-token-details="content.showInTokenDetails"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CpEditableMixin from '~/mixins/editable-component';
import OppEditButtons from './edit-buttons';
import CpInputContainer from '~/components/common/cpInputContainer';
import { CpInput, CpSelect } from '~/components/common/standalone-components/inputs';
import CpButton from '~/components/common/standalone-components/cp-button';
import ContentVisibility from '@/pages/_idIssuer/configuration/opportunities/components/content-visibility';

export default {
  name: 'ContentTelegramBox',
  components: {
    ContentVisibility,
    OppEditButtons,
    CpInputContainer,
    CpInput,
    CpButton,
    CpSelect,
  },
  mixins: [CpEditableMixin],
  props: {
    content: {
      type: undefined,
      required: true,
    },
    editContentMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      model: {
      },
      widgetTypes: [
        { value: 'discussion', text: this.$t('opportunities.editOpportunity.content.telegramBox.inputs.widgetType.options.discussion') },
        { value: 'post', text: this.$t('opportunities.editOpportunity.content.telegramBox.inputs.widgetType.options.post') },
      ],
    };
  },
  computed: {
    widgetContent() {
      return this.parseContent();
    },
  },
  created() {
    this.editMode = this.editContentMode;
    this.setConfigState();
  },
  methods: {
    changeModeProxy() {
      if (!this.editMode) {
        this.setConfigState();
      }
      this.changeMode();
    },
    setConfigState() {
      const parsedContent = this.parseContent();
      parsedContent.resourceType = parsedContent.resourceType || this.widgetTypes[0].value;

      this.model = parsedContent;
    },
    save() {
      this.$refs.cpInputContainer.validateForm()
        .then(async () => {
          if (this.$refs.cpInputContainer.isValid()) {
            this.$emit('onSave', this.getContentToSave());
            this.changeMode();
          }
        });
    },
    deleteAction() {
      this.$emit('onDeleteAction', this.model);
    },
    onContentVisibilityChanged({ visibilityType, value }) {
      this.model[visibilityType] = value;
      this.content[visibilityType] = value;
    },
    parseContent() {
        const parsedContent = JSON.parse(JSON.stringify(this.content || {}));
        const { id: resourceId, type: resourceType } = parsedContent.content ? JSON.parse(parsedContent.content) : {};

        return {
          ...parsedContent,
          resourceId,
          resourceType,
        };
    },
    getContentToSave() {
      const { resourceId: id, resourceType: type, ...rest } = this.model;

      return {
        ...rest,
        content: JSON.stringify({ id, type }),
      };
    },
  },
};
</script>
