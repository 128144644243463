<template>
  <div class="form-group">
    <label
      v-if="label"
      class="form-label"
    >
      {{ label }}
    </label>
    <quill-editor
      v-validate.initial="validate"
      :class="[ inputClass, 'editor-text', { 'border border-danger': error || controlledError }]"
      :value="sanitizedValue"
      :name="name"
      :options="editorOption"
      :data-vv-as="name.split(/(?=[A-Z])/).join(' ').toLowerCase()"
      @change="onChangeInput"
    />
    <span
      v-if="error || controlledError"
      class="invalid-feedback d-block"
    >
      {{ error || controlledError }}
    </span>
  </div>
</template>

<script>
import { quillEditor } from 'vue-quill-editor';
import { sanitize } from 'dompurify';

export default {
  name: 'MinimalHtmlTextarea',
  components: {
    quillEditor,
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    defaultViewSource: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    inputClass: {
      type: String,
      default: 'html-content',
    },
    value: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
    validate: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      controlledValue: this.value,
      controlledError: '',
      editorOption: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }],
            ['clean'],
            ['link'],
          ],
        },
      },
    };
  },
  computed: {
    sanitizedValue() {
      const value = typeof this.value === 'string' ? this.value : '';
      return sanitize(value);
    },
  },
  methods: {
    onChangeInput(item) {
      const value = item.html ? item.html : item;
      this.$emit('input', value);
    },
    isCpInput() {
      return true;
    },
  },
};
</script>
<style src="~/vendor/libs/vue-quill-editor/typography.scss" lang="scss" />
<style src="~/vendor/libs/vue-quill-editor/editor.scss" lang="scss" />
<style scoped>
    .editor-text {
      max-height: 350px;
      overflow-y: auto;
    }
    .source-text {
      max-height: 416px;
      overflow-y: auto;
    }
    .inner-layout div {
        margin-top: 10px;
    }
    .modal-title h5{
        margin-bottom: 0;
    }
    .form-label{
        margin-top: 20px;
    }
</style>
