<template>
  <div class="content-text-side">
    <p class="mr-1">
      {{ $t('opportunities.editOpportunity.content.textBox.inputs.sideType.label') }}:
    </p> {{ $t(`opportunities.editOpportunity.content.textBox.inputs.sideType.options.${side}`) }}
  </div>
</template>

<script>
export default {
  name: 'ContentSide',
  props: {
    side: {
      type: String,
      default: 'left',
    },
  },
};
</script>

<style lang="scss" scoped>
  .content-text-side {
    display: flex;
    align-items: baseline;
    p {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.93;
        color: #617d8d;
    }
  }
</style>
