<template>
  <!-- eslint-disable vue/no-v-html -->
  <div class="card mb-5 w-100">
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col-md-6">
          <h4
            class="mb-0 text-primary"
          >
            <i
              class="ion ion-ios-paper"
            /> {{ $t('opportunities.editOpportunity.content.documentsBox.title') }}
            <b-badge v-if="content.tempId">
              {{ $t('opportunities.editOpportunity.content.newText') }}
            </b-badge>
          </h4>
        </div>
        <div class="d-flex p-2 w-50 flex-row justify-content-end">
          <opp-edit-buttons
            :edit-mode="editMode"
            @changeMode="changeModeProxy"
            @update="save"
          />
          <cp-button
            class=""
            variant="primary"
            icon="ion ion-ios-trash"
            @click="deleteAction"
          >
            {{ $t('opportunities.editOpportunity.content.deleteConfirmation.deleteButton') }}
          </cp-button>
        </div>
      </div>
    </div>
    <div
      v-if="editMode"
      class="card-body"
    >
      <cp-input-container
        ref="cpInputContainer"
        v-model="model"
      >
        <div class="row">
          <div class="col-md-12">
            <cp-input
              key="title"
              v-model="model.title"
              class="mb-3"
              :label="$t('opportunities.editOpportunity.content.documentsBox.inputs.title.label')"
              :placeholder="$t('opportunities.editOpportunity.content.documentsBox.inputs.title.placeholder')"
              name="title"
              validate="required"
            />
          </div>
          <div class="col-md-12">
            <cp-input
              key="order"
              v-model="model.order"
              class="mb-3"
              input-type="number"
              :label="$t('opportunities.editOpportunity.content.documentsBox.inputs.order.label')"
              :placeholder="$t('opportunities.editOpportunity.content.documentsBox.inputs.order.placeholder')"
              name="order"
              validate="required"
            />
          </div>
        </div>
      </cp-input-container>
    </div>
    <div
      v-else
      class="card-body"
    >
      <div class="row">
        <div class="col-md-12">
          <h5>{{ content.title }}</h5>
        </div>
        <div class="col-md-12">
          <p> {{ content.order }} </p>
          <ContentSide
            :side="content.side"
          />
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
  import CpEditableMixin from '~/mixins/editable-component';
  import OppEditButtons from './edit-buttons';
  import CpInputContainer from '~/components/common/cpInputContainer';
  import { CpInput } from '~/components/common/standalone-components/inputs';
  import CpButton from '~/components/common/standalone-components/cp-button';
  import ContentSide from '@/pages/_idIssuer/configuration/opportunities/components/content-side';

  export default {
    name: 'ContentDocumentsBox',
    components: {
      OppEditButtons,
      CpInputContainer,
      CpInput,
      CpButton,
      ContentSide,
    },
    mixins: [CpEditableMixin],
    props: {
      content: {
        type: undefined,
        required: true,
      },
      editContentMode: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        model: {},
      };
    },
    created() {
      this.editMode = this.editContentMode;
      this.setConfigState();
    },
    methods: {
      changeModeProxy() {
        if (this.editMode) {
          // on cancel clear the image IDs to prevent accidental upload
          // this.clearTouched();
        } else {
          // this.$emit('onEditActivated');
          this.setConfigState();
        }
        this.changeMode();
      },
      setConfigState() {
        this.model = JSON.parse(JSON.stringify(this.content || {}));
        this.model.title = this.model.title ?? 'Documents';
      },
      save() {
        this.$refs.cpInputContainer.validateForm()
          .then(async () => {
            if (this.$refs.cpInputContainer.isValid()) {
              this.$emit('onSave', this.model);
              this.changeMode();
            }
          });
      },
      deleteAction() {
        this.$emit('onDeleteAction', this.model);
      },
      changeContent(value) {
        this.model.content = value;
      },
    },
  };
  </script>
